var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Slot" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSlots) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Spin buttons provides two slots ")]),
        _c("code", [_vm._v("increment")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("decrement")])
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("b-form-spinbutton", {
                staticClass: "mb-md-0 mb-2",
                attrs: { min: "1", max: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "increment",
                    fn: function() {
                      return [
                        _c("feather-icon", { attrs: { icon: "ChevronUpIcon" } })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "decrement",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronDownIcon" }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.value,
                  callback: function($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("b-form-spinbutton", {
                attrs: {
                  min: "0",
                  max: "6",
                  wrap: "",
                  "formatter-fn": _vm.dayFormatter
                },
                scopedSlots: _vm._u([
                  {
                    key: "increment",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronRightIcon" }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "decrement",
                    fn: function() {
                      return [
                        _c("feather-icon", {
                          attrs: { icon: "ChevronLeftIcon" }
                        })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.day,
                  callback: function($$v) {
                    _vm.day = $$v
                  },
                  expression: "day"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }