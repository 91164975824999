var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Spinbutton with Formater" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeFormater) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("To provide a formatter function, set the prop ")]),
        _c("code", [_vm._v("formatter-fn")]),
        _c("span", [_vm._v(" to a method reference.")])
      ]),
      _c(
        "div",
        [
          _c("label", { attrs: { for: "sb-days" } }, [
            _vm._v("Spin button with formatter")
          ]),
          _c("b-form-spinbutton", {
            attrs: {
              id: "sb-days",
              "formatter-fn": _vm.dayFormatter,
              min: "0",
              max: "6",
              wrap: ""
            },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          }),
          _c("b-card-text", { staticClass: "mt-1 mb-0" }, [
            _vm._v(" Value: " + _vm._s(_vm.value) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }