var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-basic")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-size")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-min-max")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-step")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-wrap")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-vertical")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-locale")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-width")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-inline")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-spinbutton-validation")],
        1
      ),
      _c("b-col", { attrs: { lg: "6" } }, [_c("form-spinbutton-formater")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("form-spinbutton-disabled-readonly")],
        1
      ),
      _c("b-col", { attrs: { cols: "12" } }, [_c("form-spinbutton-slots")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }